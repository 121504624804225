import Glide, { Breakpoints, Controls } from '@glidejs/glide/dist/glide.modular.esm'

document.addEventListener('alpine:init', () => {
    Alpine.data('featuredNews', () => ({
        g: null,
        init: function () {
            this.g = new Glide(this.$refs.glide, {
                bound: true,
                perView: 3,
                breakpoints: {
                    468: {
                        perView: 1
                    },
                    500: {
                        perView: 2
                    },
                    768: {
                        perView: 3
                    }
                },
                gap: 24
            })
        },

        show: {
            ['x-intersect.margin.200.once']() {
                if (this.g) this.g.mount({ Controls, Breakpoints })
            }
        }
    }))
})